<template>
	<!--	<back-home/>-->
	<div class="page sg">
		<div class="  content s1 flex">
			<van-search v-model="queryParams.groupName"
			            placeholder="搜索"
			            shape="round"
			            class="flex-1"
			            @search="search"/>
			<van-button type="primary"
			            round
			            size="mini"
			            class="mr-3"
			            style="padding: 4px 13px"
			            @click="search">搜索
			</van-button>
			<van-button type="primary"
			            plain
			            round
			            size="mini"
			            class="mr-3"
			            style="padding: 4px 13px"
			            @click="syncGroup">刷新
			</van-button>
		</div>
		<div class="divider s1"></div>
		<van-list v-model:loading="loading"
		          :finished="finished"
		          finished-text=""
		          @load="getListData">
			<div class="s1 p-card">
				<no-data v-if="list.length===0 && !loading"
				         title="暂无群聊，请刷新"
				         :show-button="false"/>

				<div v-for="(item,index) in list"
				     :key="index"
				     class="item flex"
				     @click="!item.isFollowRoom ? toMember(item) : ''">
					<div>
						<div class="avatar"><img v-lazy="item.chatroomImgUrl"></div>
					</div>
					<div class="flex-1">
						<div>{{ item.chatroomName ? item.chatroomName : '未命名' }}</div>
						<div class="fs-12 color-gray mt-1"
						     v-if="item.isFollowRoom">此群已开启托管，不支持设置采集对象
						</div>
					</div>

					<van-icon v-if="!item.isFollowRoom" class="pl-2" name="arrow" size=".14rem" color="#97938F"/>
				</div>
			</div>
		</van-list>

		<div class="p-footer h-end">
			<button class="btn auto gray"
			        @click="submit">关闭
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ApiGetChatrooms } from '@/api/FollowLive'
import { ApiSyncRobotChatRoom } from '@/api/Common'

export default {
	name: 'Index',
	data() {
		return {
			list: [],
			queryParams: {
				pageIndex: 1,
				pageSize: 15,
				groupName: ''
			},
			loading: false,
			finished: false
		}
	},
	computed: {
		...mapGetters(['robotInfo'])
	},
	created() {
	},
	methods: {
		async getListData(search) {
			this.loading = true
			try {
				const res = await ApiGetChatrooms({
					...this.queryParams,
					robotWxId: this.robotInfo.robotWxId
				})
				this.loading = false
				this.pageIndex++
				if (search) {
					this.list = res.dataList
				} else {
					this.list = [...this.list, ...res.dataList]
				}
				if (this.list.length >= res.recordCount) {
					this.finished = true
				}
			} catch (e) {
				this.loading = false
				this.finished = true
				console.log(e)
			}
		},
		search(search) {
			this.queryParams.pageIndex = 1
			this.getListData(search)
		},
		toMember(val) {
			this.$router.push({
				path: '/selectMemberGather',
				query: {
					chatroomId: val.chatroomId,
					chatroomImgUrl: val.chatroomImgUrl,
					chatroomName: val.chatroomName
				}
			})
		},
		async syncGroup() {
			try {
				await ApiSyncRobotChatRoom(this.robotInfo.robotWxId)
				this.queryParams.groupName = ''
				this.$Toast('刷新成功')
				this.search('search')
			} catch (e) {
				console.log('ApiSyncRobotChatRoom', e)
			}
		},
		submit() {
			this.$router.replace('/followMaterial')
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";

.sg {
	margin: 0.12rem 0.15rem 0;
	background: #fff;
}
</style>
<style>


.sg .van-search .van-cell {
	padding: 0.01rem 0.08rem 0.01rem 0
}

.sg .van-field__left-icon {
	color: #ccc;
}
</style>
